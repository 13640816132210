<template>
    <div class="row justify-content-center">
        <Loading v-show="loading" />

        <div class="col-lg-5 order-lg-2 mb-5 mb-lg-0">
            <div class="card bg-secondary shadow border-0">
                <div class="card-header bg-transparent">
                    <h3>Order Summary</h3>

                    <hr class="my-4" />
                    <div v-show="cartModel.length == 0">
                        <h4 style="text-align:center; color:#555">Your cart is empty, browse our amazing products <a href="/ourbooks">here</a></h4>
                    </div>
                    <form @submit.prevent v-show="cartModel.length > 0" class="order-form">
                        <div v-for="product, index in cartModel" :key="index">
                            <div class="cart flex-container">
                                <div class="flex-left">
                                    <div :style="{ backgroundImage: (product.productImage) 
                                                ? 'url('+product.productImage+')' : 'url(img/' + product.productUrlCode + '/samplecover.jpg)' }"
                                        class="cartItem">
                                    </div>
                                </div>
                                <div class="flex-mid" style="flex-grow:1">
                                    <div>
                                        <b>{{ product.productName }}</b>
                                        <div v-if="product.childname">for: <b>{{ product.childname }}</b></div>
                                        <div v-if="product.message">with message:</div>
                                        <div v-if="product.message"><b>{{ product.message }}</b></div>
                                    </div>
                                    <div class="bookablue">
                                          <span :class="{'text-danger': product.productPriceBeforeDiscount ? true : false }">{{ parseInt(product.productPrice).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'IDR',
                                                }) }}</span>
                                        <span style="padding-left: 10px; text-decoration: line-through;" v-if="product.productPriceBeforeDiscount">{{ parseInt(product.productPriceBeforeDiscount).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'IDR',
                                                }) }}</span></div>
                                </div>
                                <div class="flex-right icons">
                                    <div style="text-align:end; padding-left:5px">
                                        <i @click="addCartItem(product)" class="icon fa fa-plus"></i>
                                        <i @click="deleteCartItem(index)" class="icon fa fa-times"></i>
                                    </div>   
                                </div>      
                            </div>
                            <h5 v-if="product.selected_child" class="mt-2">Personalization:</h5> 
                            <div v-if="product.selected_child" class="flex-container">
                                <div class="mx-1 personalization"
                                    :style="{ backgroundImage: 'url('+ product.selected_child + ')' }">
                                </div>
                                <div v-if="product.selected_candle" class="mx-1 personalization"
                                    :style="{ backgroundImage: 'url('+ product.selected_candle + ')' }">
                                </div>
                                <div v-if="product.selected_cover" class="mx-1 personalization"
                                    :style="{ backgroundImage: 'url('+ product.selected_cover + ')' }">
                                </div>
                                <div v-if="product.selected_child2" class="mx-1 personalization"
                                    :style="{ backgroundImage: 'url('+ product.selected_child2 + ')' }">
                                </div>    
                            </div>              
                            <hr class="my-3"/>
                        </div>

                        <!-- <div>
                            <h4 style="text-align:center; color:#555">Browse more of our amazing products <a href="/ourbooks">here</a></h4>
                        </div>
                        <hr/> -->
                        <!-- Summary -->
                        <h4 style="text-align:center; color:#555">Add more amazing products <a href="/ourbooks">here</a></h4>
                        <hr class="my-3"/>
                        <div class="flex-container">
                            <div style="flex-grow: 1;">Subtotal <span style="color:darkgray">{{ cartModel.length }} item(s)</span></div>
                            <div style="text-align: right;">{{cartTotal.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        })
                                        }}</div>
                        </div>
                        <div class="flex-container" v-show="model.voucherVerified">
                            <div style="flex-grow: 1;">Discount</div>
                            <div style="text-align: right;">- {{(model.discount).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        })
                                        }}</div>
                        </div>
                        <hr class="my-3" />
                        <!-- <div class="flex-container">
                            <div style="flex-grow: 1;">Shipping</div>
                            <div style="text-align: right;">{{((model.selectedCourier.price == 0) ? '-' : model.selectedCourier.price.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        }))
                                        }}</div>
                        </div> -->
                        <div class="flex-container" v-show="model.discountShipping > 0">
                            <div style="flex-grow: 1;">Discount Shipping</div>
                            <div style="text-align: right;">- {{(model.discountShipping).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        })
                                        }}</div>
                        </div>
                        <hr class="my-3" />
                        <div class="flex-container">
                            <div style="flex-grow: 1;"><b>Total</b></div>
                            <div style="text-align: right;"><b>{{(model.total).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        })
                                        }}</b></div>
                        </div>

                        <div @click="toggleVoucher()" class="btn my-2">Do you have a voucher code?</div>
                        <div class="flex-container" v-show="model.useVoucher">
                            <base-input alternative=""
                                        placeholder="Voucher code (optional)"
                                        input-classes="form-control-alternative"
                                        v-model="model.voucherCode"
                                        style="flex-grow: 1;"
                            />
                            <div class="btn btn-primary btn-apply ml-2" @click="processVoucher()">Apply</div>
                        </div>
                    </form>
                    
                    
                </div>
                
            </div>    
        </div>

        <div class="col-lg-7 order-lg-1 mt-4">
        <!-- Address -->
        <h1 class="mb-4">Shipping information</h1>
                <div class="pl-lg-4">
                    <div class="row">
                        <div class="col-lg-6">
                            <base-input alternative=""
                                        label="Name"
                                        placeholder="Your Name"
                                        input-classes="form-control-alternative"
                                        v-model="model.name"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <base-input alternative=""
                                        label="Phone Number"
                                        placeholder="081234567890"
                                        input-classes="form-control-alternative"
                                        v-model="model.phoneNumber"
                            />
                        </div>
                        <div class="col-lg-6">
                            <base-input alternative=""
                                        label="Email address"
                                        placeholder="jesse@example.com"
                                        input-classes="form-control-alternative"
                                        v-model="model.email"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <base-input alternative=""
                                        label="Address"
                                        placeholder="Home Address"
                                        input-classes="form-control-alternative"
                                        v-model="model.address"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <label class="form-control-label">Province</label>
                            <select class="form-select form-control-alternative" aria-label="Province" @change="selectProvince($event)">
                                <option selected></option>
                                <option v-for="province in locations.provinces" :key="province.province_code" :value="province.province_code+'-'+province.province_name">{{ province.province_name }}</option>
                            </select>
                        </div>
                        <div class="col-lg-4">
                            <label class="form-control-label">City</label>
                            <select class="form-select form-control-alternative" aria-label="City" @change="selectCity($event)">
                                <option selected></option>
                                <option v-for="city in locations.cities.filter((city) => city.city_code.includes(this.model.province_code+'.'))" :key="city.city_code" :value="city.city_code+'-'+city.city_name">{{ city.city_name }}</option>
                            </select>
                        </div>
                        <div class="col-lg-4">
                            <label class="form-control-label">Subdistrict</label>
                            <select class="form-select form-control-alternative" aria-label="Subdistrict" @change="selectSubdistrict($event)">
                                <option selected></option>
                                <option v-for="subdistrict in locations.subdistricts.filter((subdistrict) => subdistrict.subdistrict_code.includes(this.model.city_code+'.'))" :key="subdistrict.subdistrict_code" :value="subdistrict.subdistrict_code+'-'+subdistrict.subdistrict_name">{{ subdistrict.subdistrict_name }}</option>
                            </select>
                        </div>
                        <div class="col-lg-4">
                            <base-input alternative=""
                                        label="Postal code"
                                        placeholder="Postal code"
                                        input-classes="form-control-alternative"
                                        v-model="model.zipCode"
                            />
                        </div>
                    </div>
                </div>
                <!-- <hr class="my-4" />
                <h3 class="mb-1">Shipping</h3>
                <div v-if="!shippingLoaded">
                    <div>{{ shippingMessage }}</div>
                </div>
                <div v-if="shippingLoaded">
                    <div class="my-3">
                        <div v-for="courier, index in courierList.data" :key="index">
                            <div v-if="!courier.errors" 
                            @click="selectCourier(courier)"
                            class="px-2 py-2 my-1 card d-flex"
                            :style="{backgroundColor: (courier.courier == model.selectedCourier.courier
                                                    && courier.service == model.selectedCourier.service)
                                                    ? '#0577a6' : 'white',
                                    color: (courier.courier == model.selectedCourier.courier
                                                    && courier.service == model.selectedCourier.service)
                                                    ? 'white' : '#525f7f'}"
                            style="justify-content: space-between">
                            <div>
                                <b>{{ courier.courier }} - {{ courier.service }}</b>
                                <div style="font-size:0.8em">{{ courier.duration }}</div>
                                <div style="font-size:0.8em">{{ courier.estimation }}</div>                            
                            </div>
                            <div>
                                <span>{{ parseInt(courier.price).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        }) }}</span>
                            </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <hr class="my-4" />
                <h3 class="mb-1">Payment</h3>
                <div v-if="!model.subdistrict_code">
                    <div>{{ shippingMessage }}</div>
                </div>
                <div v-if="model.subdistrict_code">
                    <h6>Choose your payment method:</h6>
                    <div class="px-2 py-2 my-1 card d-flex" 
                        :style="{backgroundColor: (model.selectedPaymentMethod == 'Bank Transfer')
                                                    ? '#0577a6' : 'white',
                                    color: (model.selectedPaymentMethod == 'Bank Transfer')
                                                    ? 'white' : '#525f7f'}"
                        @click="selectPaymentMethod('Bank Transfer')"
                        >
                        <b>Bank Transfer</b>
                        <div v-if="model.selectedPaymentMethod == 'Bank Transfer'">
                        <span>Transfer manually to Bank BCA will be needed to check out using this payment method. Our staff will verify the transfer once it it done.
                            <br/>--
                            <br/>Transfer secara manual ke Bank BCA dengan menggunakan metode pembayaran ini. Staff kami akan segera melakukan verifikasi pembayaran setelah dana ditransfer.</span>
                        </div>
                    </div>
                    <div class="px-2 py-2 my-1 card d-flex"
                        :style="{backgroundColor: (model.selectedPaymentMethod == 'xendit')
                                                    ? '#0577a6' : 'white',
                                    color: (model.selectedPaymentMethod == 'xendit')
                                                    ? 'white' : '#525f7f'}"
                        @click="selectPaymentMethod('xendit')"
                        >
                        <b>Xendit (Credit Card, Bank VA, QRIS)</b>
                    </div>
                    <!-- <div class="px-2 py-2 my-1 card d-flex"
                        :style="{backgroundColor: (model.selectedPaymentMethod == 'qrplayhouse-academy')
                                                    ? '#0577a6' : 'white',
                                    color: (model.selectedPaymentMethod == 'qrplayhouse-academy')
                                                    ? 'white' : '#525f7f'}"
                        @click="selectPaymentMethod('qrplayhouse-academy')"
                        >
                        <b>QRPlayhouse Academy</b>
                    </div> -->
                </div>
                <hr class="my-4" />
                <div v-if="!(model.selectedPaymentMethod && model.subdistrict_code)">
                    <div class="btn btn-primary float-right mb-3 disabled" disabled>Confirm My Order</div>
                </div>   
                <div v-if="model.selectedPaymentMethod && model.subdistrict_code">
                    <h4 class="error err">{{ errorMsg }}</h4>
                    <div class="btn btn-primary float-right mb-3" @click="processOrder()">
                        <span>Confirm My Order</span>
                        <i class="ni ni-bold-right"></i>
                    </div>
                </div>

        </div>
    </div>
</template>
<script>

import Loading from "../components/Loading";
import firebase from "firebase/app";
import firebaseApp from "../firebase/firebaseInit";
const db = firebaseApp.firestore();

export default {
    name: 'checkout',
    components: {
        Loading,
    },
    data() {
        return {
            error: false,
            errorMsg: "",
            loading: false,
            shippingLoaded: false,
            shippingSelected: false,
            shippingMessage: 'Please input the destination province, city and subdistrict',
            courierList: {},
            model: {
                name: '',
                phoneNumber: '',
                email: '',
                address: '',
                city_name: '',
                city_code: '',
                province_name: '',
                province_code: '',
                district_name: '',
                district_code: '',
                subdistrict_name: '',
                subdistrict_code: '',
                country: 'Indonesia',
                zipCode: '',
                useVoucher: false,
                voucherVerified: false,
                voucherCode: '',
                voucherId: '',
                total: 0,
                discount: 0,
                discountShipping: 0,
                freeShipping: false,
                totalBeforeDiscount: 0,
                selectedCourier: {
                    courier: '',
                    courierCode: '',
                    service: '',
                    price: 0,
                },
                selectedPaymentMethod: 'Bank Transfer'
            },
            locations: {
                provinces: [],
                cities: [],
                subdistricts: [],
            }
        }
    },
    methods: {
        toggleVoucher(){
            this.model.useVoucher = !this.model.useVoucher;
        },
        async processVoucher(){
            
            if(this.model.voucherCode == ''){
                alert('Sorry, please fill in voucher code correctly');
            // } 
            // else if(this.model.voucherCode == 'PLAYHOUSEGRADUATION3'){
            //     this.model.voucherVerified = true;
            //     this.model.totalBeforeDiscount = this.cartTotal;
            //     this.model.discount = 0.3 * this.cartTotal;
            //     this.model.total = this.cartTotal - this.model.discount + this.model.selectedCourier.price;
            // } else if(this.model.voucherCode == 'KLIK2022' || this.model.voucherCode == 'CLA2022'){
            //     this.model.voucherVerified = true;
            //     this.model.totalBeforeDiscount = this.cartTotal;
            //     this.model.discount = 0.1 * this.cartTotal;
            //     this.model.total = this.cartTotal - this.model.discount + this.model.selectedCourier.price;
            } else {

                const vouchers = await db.collection("vouchers").where('code', '==', this.model.voucherCode).get();
                console.log('this.model.voucherCode', this.model.voucherCode);
                // console.log('vouchers', vouchers);
                if (vouchers.empty){
                    alert('Sorry, but voucher code not found, expired or already fully redeemed');
                    this.model.voucherCode = '';
                } else {
                    this.model.discount = 0;
                    this.model.discountShipping = 0;
                    vouchers.forEach( doc => {
                        const voucher = doc.data();
                        console.log('voucher', voucher);
                        this.model.voucherId = doc.id;
                        if(!voucher.active || voucher.expiry_date > Date.now()){
                            alert('Sorry, but voucher code is unavailable or has expired');
                            this.model.voucherCode = '';
                        } else if(voucher.quota <= voucher.used && voucher.quota != "-1") {
                            alert('Sorry, but voucher code already fully redeemed or exceed quota limit');
                            this.model.voucherCode = '';
                        } else {
                            if(voucher.type=="discount_by_value"){
                                this.model.voucherVerified = true;
                                this.model.totalBeforeDiscount = this.cartTotal;
                                this.model.discount = parseFloat(voucher.value);
                                if(voucher.free_shipping){
                                    this.model.discountShipping = this.model.selectedCourier.price;
                                    this.model.freeShipping = true;
                                }
                                this.model.total = this.cartTotal - this.model.discount + this.model.selectedCourier.price - this.model.discountShipping;
                            } else if(voucher.type=="discount_by_percentage"){
                                this.model.voucherVerified = true;
                                this.model.totalBeforeDiscount = this.cartTotal;
                                this.model.discount =  parseFloat(voucher.value) * this.cartTotal;
                                if(voucher.free_shipping){
                                    this.model.discountShipping = this.model.selectedCourier.price;
                                    this.model.freeShipping = true;
                                }
                                this.model.total = this.cartTotal - this.model.discount + this.model.selectedCourier.price - this.model.discountShipping;
                            } else if(voucher.type=="free_lowest_price_product"){
                                if(voucher.minimumQty > this.cartModel.length){
                                    alert("Sorry, but you haven't reach the minimum quantity of "+voucher.minimumQty+" products for this voucher");
                                    this.model.voucherCode = '';
                                } else {
                                    this.model.voucherVerified = true;
                                    this.model.totalBeforeDiscount = this.cartTotal;
                                    
                                    let lowestPrice = 0;
                                    this.cartModel.forEach(cart => {
                                        console.log(cart.productPrice,' vs ',lowestPrice);
                                        lowestPrice = (cart.productPrice<lowestPrice) ? cart.productPrice : (lowestPrice == 0) ? cart.productPrice : lowestPrice;
                                    })

                                    this.model.discount = lowestPrice;

                                    if(voucher.free_shipping){
                                        this.model.discountShipping = this.model.selectedCourier.price;
                                        this.model.freeShipping = true;
                                    }

                                    this.model.total = this.cartTotal - this.model.discount + this.model.selectedCourier.price - this.model.discountShipping;
                                }
                            }

                            if(voucher.free_shipping) {
                                this.model.discount = this.model.discount + this.model.selectedCourier.price;
                                this.model.total = this.cartTotal - this.model.discount + this.model.selectedCourier.price;
                            }
                        }
                        // console.log(doc.data());
                        
                        return;
                    })
                }
            }
        },
        addCartItem(product) {
            const productUrlCode = product.productUrlCode;
            if (confirm("Are you sure you want to add this cart item more?") == true) {
                let url = (productUrlCode == 'kid-and-the-dragon'
                            || productUrlCode == 'where-are-you' 
                            || productUrlCode == 'a-very-dinosaur-birthday' 
                            || productUrlCode == 'a-very-unicorn-birthday' 
                            || productUrlCode == 'bali' 
                            || productUrlCode == 'blessings-of-ramadan' 
                            || productUrlCode == 'jakarta' 
                            || productUrlCode == 'good-night' 
                            || productUrlCode == 'one-day-you-will-fly' 
                            || productUrlCode == 'happy-birthday' 
                            || productUrlCode == 'promoted-to-big-brother-sister' 
                            || productUrlCode == 'ramadan-seru' 
                            || productUrlCode == 'yogyakarta' 
                            || productUrlCode == 'welcome-to-the-world' 
                            || productUrlCode == 'where-are-you-birthday' )
                            ? '/'+productUrlCode
                            : 
                                (product.type == 'Personalized Book')
                                ? '/custombook/'+productUrlCode
                                : '/product/'+productUrlCode
                            ;
                this.$router.push(url);
            }
        },
        deleteCartItem(index) {
            if (confirm("Are you sure you want to delete this cart item?") == true) {
                this.cartModel.splice(index, 1);
                this.$store.commit("setCartState", this.cartModel);
            }
            this.calculateTotal();
        },
        calculateTotal(){
            this.model.total = this.cartTotal - this.model.discount + this.model.selectedCourier.price - this.model.discountShipping;
        },
        selectProvince(event){
            // console.log(this.locations.provinces[event.target.value].province_name, this.locations.provinces[event.target.value].province_code);      
            this.model.province_code = event.target.value.split('-')[0];
            this.model.province_name = event.target.value.split('-')[1];
        },
        selectCity(event){
            // console.log(this.locations.provinces[event.target.value].province_name, this.locations.provinces[event.target.value].province_code);      
            this.model.city_code = event.target.value.split('-')[0];
            this.model.city_name = event.target.value.split('-')[1];
        },
        selectSubdistrict(event){
            // console.log(this.locations.provinces[event.target.value].province_name, this.locations.provinces[event.target.value].province_code);      
            this.model.subdistrict_code = event.target.value.split('-')[0];
            this.model.subdistrict_name = event.target.value.split('-')[1];
            // this.checkShipping();
        },
        selectCourier(courier){
            this.shippingSelected = true;
            this.model.selectedCourier = courier;
            if(this.model.freeShipping){
                this.model.discountShipping = this.model.selectedCourier.price;
            }
            this.model.total = this.cartTotal - this.model.discount + this.model.selectedCourier.price - this.model.discountShipping;
        },
        selectPaymentMethod(paymentMethod){
            this.model.selectedPaymentMethod = paymentMethod;
        },
        async getToken(){
            const doc = await db.collection("shipdeo").doc("token").get();
            if (!doc.exists) {
                console.log('No such document!');
                alert('No Token Found!');
            } else {
                // console.log('Document data:', doc.data());
                return doc.data().token
            }
        },
        async checkShipping(){
            this.shippingMessage = 'Loading shipping couriers...';
            const token = await this.getToken();

            const shipdeo_url = 'https://main-api-production.shipdeo.com/v1';
            const endpoint = '/couriers/pricing';
            const options = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer '+token,
                    'Content-Type': 'application/json'
                },
                data: {
                    "couriers": [
                        "sicepat", "anteraja", "posindonesia"
                    ],
                    "is_cod": true,
                    "origin_lat": "-6.223749735697065",
                    "origin_long": "106.66198662463152",
                    "origin_province_code": "36",
                    "origin_province_name": "Banten",
                    "origin_city_code": "36.71",
                    "origin_city_name": "Kota Tangerang",
                    "origin_subdistrict_code": "36.71.11",
                    "origin_subdistrict_name": "Pinang",
                    "origin_postal_code": "15144",
                    "sender_name": "Bookabook.id",
                    "sender_phone": "string",
                    "destination_lat": "string",
                    "destination_long": "string",
                    "destination_province_code": this.model.province_code,
                    "destination_province_name": this.model.province_name,
                    "destination_city_code": this.model.city_code,
                    "destination_city_name": this.model.city_name,
                    "destination_subdistrict_code": this.model.subdistrict_code,
                    "destination_subdistrict_name": this.model.subdistrict_name,
                    "destination_postal_code": "string",
                    "recipient_name": this.model.name,
                    "recipient_phone": this.model.phoneNumber,
                    "items": [
                        {
                        "name": "string",
                        "description": "string",
                        "weight": 200,
                        "weight_uom": "gram",
                        "qty": 0,
                        "value": 0,
                        "width": 0,
                        "height": 0,
                        "length": 0,
                        "is_wood_package": false,
                        "dimension_uom": "cm"
                        }
                    ],
                    "isCallWeight": true
                },
                url: shipdeo_url+endpoint,
                method: 'post',
            };

            const thisVue = this;
            this.$http(options)
                .then(function (response) {
                    if(response.status == 200){
                        // console.log('data received and updating data ',response.data);
                        thisVue.courierList = response.data;
                        thisVue.shippingMessage = 'Please select your shipping courier & service';
                        thisVue.shippingLoaded = true;
                    } else {
                        alert('There is error in shipping data, please contact our Customer Service WhatsApp in +62-888-0185-0011');
                        console.log('shipping response error', response);
                    }
                }).catch(function (error) {
                    console.log(error);
                });


        },
        async processOrder(){
            if (
                this.model.email !== "" &&
                this.model.password !== "" &&
                this.model.name !== "" &&
                this.model.lastName !== "" &&
                this.model.username !== ""
            ) {
                this.loading = true;
                this.error = false;
                this.errorMsg = "";

                this.model.cart = this.cartModel;
                // console.log(this.model);

                if(this.model.discount==0){
                    this.model.totalBeforeDiscount = this.cartTotal + this.model.selectedCourier.price;
                    this.model.total = this.cartTotal + this.model.selectedCourier.price;
                }

                const date = new Date();
                let isodate = date.toISOString().replace(/[^\d]/g,'').slice(0, -3);
                
                this.model.date = date;
                this.model.orderID = '#' + this.model.name.substring(0,3).toUpperCase() + '-' + isodate.substring(0,6) + '-' + isodate.substring(7,14);
                const dataBase = await db.collection("orders").doc(this.model.orderID);
                
                try {
                    const order = Object.assign({}, this.model);
                    console.log(order);
                    console.log(order.orderID);

                    await dataBase.set(order);
                    if(this.model.voucherId)
                        await db.collection("vouchers").doc(this.model.voucherId).update({used: firebase.firestore.FieldValue.increment(1)});
                    
                    this.$store.commit("setLastOrderState", order);
                    this.$store.commit("setCartState", []);

                    this.loading = false; 
                    this.$router.push({ name: "thankyou" });

                } catch (error) {
                    console.error(error);
                    alert(error);
                }
                

                

                
                return;
            }
            this.error = true;
            this.errorMsg = "Please fill out all the fields!";
            alert(this.errorMsg);
            return;
        },
        async getLocation() {
            const provincesRef = db.collection('shipdeo').doc('locations').collection('provinces');
            const provincesSnapshot = await provincesRef.get();
            if (provincesSnapshot.empty)
                console.log('No matching documents.');
            else
                provincesSnapshot.forEach( doc => {this.locations.provinces.push(doc.data()) })

            this.locations.provinces.sort((a,b) => (a.province_name > b.province_name) ? 1 : ((b.province_name > a.province_name) ? -1 : 0))

            
            const citiesRef = db.collection('shipdeo').doc('locations').collection('cities');
            const citiesSnapshot = await citiesRef.get();
            if (citiesSnapshot.empty)
                console.log('No matching documents.');
            else
                citiesSnapshot.forEach( doc => {this.locations.cities.push(doc.data()) })
            
            const subdistrictsRef = db.collection('shipdeo').doc('locations').collection('subdistricts');
            const subdistrictsSnapshot = await subdistrictsRef.get();
            if (subdistrictsSnapshot.empty)
                console.log('No matching documents.');
            else
                subdistrictsSnapshot.forEach( doc => {this.locations.subdistricts.push(doc.data()) })
            
            
            // console.log(this.locations);
            // this.$store.commit("setOrderIDState", this.model.orderID);
        }
    },
    mounted() {
        if (this.locationModel == null) {
            this.getLocation();
            this.model.total = this.cartTotal - this.model.discount + this.model.selectedCourier.price - this.model.discountShipping;
        }
    },
    computed: {
        cartModel() {
          return this.$store.state.cart;
        },
        locationModel() {
          return this.$store.state.location;
        },
        cartTotal() {
            var total = 0;
            this.$store.state.cart.forEach(product => {
                total += product.productPrice
            })

            return total;
        },
    },
}
</script>
<style>

    .btn { height: fit-content; }
    .error { color: red}
    
</style>
